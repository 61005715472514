<template>
  <v-dialog v-model="dialog" width="800">
    <v-card>
      <v-card-text>
        <tenant-info :sm="sm" :md="md" :lg="lg" :xl="xl" />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TenantInfo from "./TenantInfo.vue";

export default {
  props: {
    value: { type: Boolean, default: false },
    sm: { type: String, default: "12" },
    md: { type: String, default: "6" },
    lg: { type: String, default: "6" },
    xl: { type: String, default: "6" },
  },

  components: {
    TenantInfo,
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
